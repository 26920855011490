import React from 'react'

function InfoCard({info}) {
    const {name, id, file, artist, canvasDimensions, description, price} = info
    return (
        <div className=' p-6 my-4'>
            <p className='font-bold'>{`${artist} Collection`}</p>
            <div className='flex flex-col h-full justify-between'>
                <span>
                    <h1 className='font-bold'>{name}</h1>
                </span>
                <span className=''>
                    <p>{description}</p>
                </span>
            </div>
        </div>
    )
}

export default InfoCard