import { BrowserRouter, Routes, Route } from "react-router-dom";
import './App.css';
import Layout from "./components/layout/Layout";
import Collections from "./components/art/collections/Collections";
import Home from "./routes/Home";
import Collection from "./components/art/collections/Collection";
import SingleExhibit from "./components/art/pieces/SingleExhibit";
import Portfolio from './routes/portfolio/Portfolio'
import AboutMe from "./routes/about-me/AboutMe";
import Shop from "./routes/shop/Shop";

function App() {
  return (
    <BrowserRouter>
        <Routes>
            <Route path="/" element={<Layout />}>
                <Route index element={<Home />} />
                <Route path='portfolio' element={<Portfolio />} />
                <Route path='about-me' element={<AboutMe />} />
                <Route path='shop' element={<Shop />} />
                <Route path='art'>
                    <Route path='collections'>
                        <Route path='all' element={<Collections />} />
                        <Route path=':collectionName'>
                            <Route index element={<Collection />} />
                            <Route path=':pieceName' element={<SingleExhibit />} />
                        </Route>
                    </Route>
                </Route>
            </Route>
        </Routes>
    </BrowserRouter>
  );
}

export default App;
