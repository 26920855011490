const shopConfig = {
    images: [
        {
            title: 'Muse',
            source:'https://d2hfcuq72ogdij.cloudfront.net/shop/tote-muse.jpeg',
            price: 20 
        },
        {
            title: 'JRHT \'23',
            source: 'https://d2hfcuq72ogdij.cloudfront.net/shop/tote-jrht-23.jpeg',
            price: 20
        },
        {
            title: 'Heat Signature',
            source: 'https://d2hfcuq72ogdij.cloudfront.net/shop/tote-heat-signature.jpeg',
            price: 20
        },
        {
            title: 'Cosmic Crown\'s Blue',
            source: 'https://d2hfcuq72ogdij.cloudfront.net/shop/tote-blauwsel.jpeg',
            price: 20
        }
    ],
    homeCarousel: [
        [
            {title: 'JRHT \'23', source: 'https://d2hfcuq72ogdij.cloudfront.net/shop/tote-jrht-23.jpeg'},
            {title: 'Heat Signature', source: 'https://d2hfcuq72ogdij.cloudfront.net/shop/tote-heat-signature.jpeg'},
            {title: 'Cosmic Crown\'s Qing', source: 'https://d2hfcuq72ogdij.cloudfront.net/shop/tote-blauwsel.jpeg'},
        ],
        [
            {title: 'The Faceless Blue', source: 'https://d2hfcuq72ogdij.cloudfront.net/shop/tote-faceless-blue.jpeg'},
            {title: 'Yin Flow', source: 'https://d2hfcuq72ogdij.cloudfront.net/shop/tote-yin.jpeg'},
            {title: 'Muse', source: 'https://d2hfcuq72ogdij.cloudfront.net/shop/tote-muse.jpeg'},
        ],
        [
            {title: 'Yang Flow', source: 'https://d2hfcuq72ogdij.cloudfront.net/shop/tote-yang.jpeg'},
            {title: 'Sight Beyond Sight', source: 'https://d2hfcuq72ogdij.cloudfront.net/shop/tote-sight-beyond-sight.jpeg'},
            {title: 'Galaxy', source: 'https://d2hfcuq72ogdij.cloudfront.net/shop/tote-galaxy.jpeg'},
        ],
    ]
}

module.exports = shopConfig