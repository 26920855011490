import React from 'react'
import PieceGrid from '../../components/art/pieces/PieceGrid'
import Piece from '../../components/art/pieces/Piece2'
import { ArtConfig } from '../../components/art/config/ArtConfig'

function Portfolio() {
    const collections = Object.values(ArtConfig.collections)
    return (
        <div className='px-2 py-4 flex flex-col gap-0 sm:px-10'>
            {collections.map((collection, index) => {
                return (
                    <div>
                        <h1>{collection.name}</h1>
                        <PieceGrid pieceConfig={collection} key={index} />
                    </div>

                )
            })}
        </div>
    )
}

export default Portfolio