import React from 'react'

function ShopPiece({item}) {
    return (
        <div className='flex flex-col justify-center w-full h-full shadow-lg shadow-black rounded-lg'>
            <div className='flex justify-center w-full p-1 rounded-lg'>
                <img src={item.source} alt={item.title.toLowerCase().replace(/ /g, '-')} className='h-full rounded-lg'/>
            </div>
            <button className='rounded-full uppercase shadow-md shadow-black px-4 py-2 w-4/5 my-4 mx-auto font-bold'>COMING SOON</button>
            {/* <button className='rounded-full uppercase border-2 border-black px-4 py-2 w-2/3 my-4 mx-auto'>{item.title + '  $' + item.price}</button> */}
        </div>
    )
}

export default ShopPiece