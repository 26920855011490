import React from 'react'
import ShopGrid from '../../components/shop/ShopGrid'
import shopConfig from '../../components/shop/ShopConfig'

function Shop() {
    return (
        <div className='relative py-4 pt-4 px-2 sm:px-12'>
            <div className='opacity-80'>
                <h1 className='text-xl font-semibold'>Shop</h1>
                <ShopGrid items={shopConfig.images} />
            </div>
            <div className='absolute flex justify-center items-center top-1/4 left-[10%] w-4/5 aspect-video bg-yellow-600 rounded-full font-bold text-2xl text-white'>
                AVAILABLE SOON
            </div>
        </div>
    )
}

export default Shop