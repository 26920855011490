export const NavbarConfig = {
    logo: {
        src: '',
        name: 'Studio Qinghua'
    },
    navItems: [
        {name: 'Home', path: '/'},
        {name: 'About me', path: '/about-me'},
        {name: 'Portfolio', path: '/portfolio'},
        {name: 'Shop', path: '/shop'}
    ],
    socials: [
        {platform: 'OpenSea', url: ''},
        {platform: 'LinkedIn', url: ''},
    ]
}