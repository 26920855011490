import React, { useState } from 'react'
import './piece.css'
import { Link } from 'react-router-dom'
import InfoIcon from '../../../assets/information.png'

function Piece({pieceConfig}) {
    const {name, file, artist, canvasDimensions, description, price, id, collectionId} = pieceConfig
    const [isHovered, setIsHovered] = useState()
    const [showInfo, setShowInfo] = useState(false)

    const handleClick = () => {
        setShowInfo(prev => !prev)
    }

    return (
        <div className='w-full h-fit border-2 border-black bg-gray-700 rounded-lg shadow-lg shadow-black'>
            <div className='overflow-hidden flex flex-col' onClick={handleClick}>
                <Link to={`/art/collections/${collectionId}/${id}`} className='no-underline'>
                    <div className='overflow-hidden'>
                        <img 
                            className='h-full w-full block rounded-lg scale-125'
                            src={file} 
                            alt={name.toLowerCase()}/>
                    </div>
                    <div className='text-white h-fit p-2 mt-auto mb-0'>
                        <p className='font-bold my-0'>{`"${name}"`}</p>
                        <p className='mt-1'>{`${artist} Collection`}</p>
                    </div>
                </Link>      
            </div>
        </div>
    )
}

export default Piece