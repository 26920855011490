const text = [
    "To me creativity is essential to my growth as a person first and an artist second. When I paint, the loud noises and images in my head are transformed into beautiful shades of yellow, blue, red, green and every other shade in between.",
    "In the time I’ve lived abroad I’ve invested many hours in photography, videography, painting and graphic design. All these skills aided me in the process of converting my physical art  into digital art pieces that are located in the metaverse.",
    "That’s how Studio Qinghua came about. Art helps me in understanding myself as a person and where I come from. When I paint, its referenced from an entire universe thats situated in the depths of my mind. This includes references such as my roots, life experiences and life lessons. One of my goals is to make that growth a possibility for future creatives back home somehow.",
]

const images = [
    'https://d2hfcuq72ogdij.cloudfront.net/about-me/about-me-1.jpeg',
    'https://d2hfcuq72ogdij.cloudfront.net/about-me/about-me-2.jpeg',
    'https://d2hfcuq72ogdij.cloudfront.net/about-me/about-me-3.jpeg',
]

module.exports = {
    text: text,
    images: images
}