import React from 'react'
import { useNavigate } from 'react-router-dom'
import CloseButton from 'react-bootstrap/CloseButton'

function BackButton({styles}) {
    const navigate = useNavigate()

    const handleBack = () => {
        navigate(-1)
    }

    return (
        <CloseButton onClick={handleBack} className={styles} />
    )
}

export default BackButton