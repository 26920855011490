import React from 'react'
import { useParams } from 'react-router-dom'
import { ArtConfig } from '../config/ArtConfig'
import InfoCard from './InfoCard'
import BackButton from '../../layout/nav/BackButton'

function SingleExhibit() {
    const {collectionName, pieceName} = useParams()
    console.log(ArtConfig.collections[collectionName])
    const pieceInfo = ArtConfig.collections[collectionName].pieces.find(piece => piece.id === pieceName)

    return (
        <div className='relative w-full h-screen sm:h-full sm:overflow-hidden pt-4 sm:px-14 sm:py-10'>
            <BackButton styles='absolute top-4 right-6'/>
            <div className='w-[95%] shadow-xl shadow-black mx-auto rounded-lg mt-8 sm:w-4/6'>
                <img src={pieceInfo.file} alt={pieceInfo.id} className='w-full h-full rounded-lg'/>
            </div>
            
            <InfoCard info={pieceInfo} />
        </div>
    )
}

export default SingleExhibit