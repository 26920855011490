import React from 'react'
import Piece2 from './Piece2'

function PieceGrid({pieceConfig}) {
    console.log(pieceConfig)
    return (
        <div className='grid pb-4 sm:pt-0 grid-cols-2 max-w-screen gap-2 sm:gap-x-4'>
            {pieceConfig.pieces.map((piece, index) => {
                return <Piece2 pieceConfig={piece} collectionId={pieceConfig.collectionId} key={index}/>
            })}
        </div>
    )
}

export default PieceGrid