import React from 'react'
import Container from 'react-bootstrap/Container'
import Nav from 'react-bootstrap/Nav'
import Navbar from 'react-bootstrap/Navbar'
import { NavbarConfig } from './NavbarConfig'
import Logo from '../../../assets/logos/main.png'
import TwitterIcon from '../../../assets/socials/002-twitter.png'
import InstagramIcon from '../../../assets/socials/001-instagram.png'
import { Link } from 'react-router-dom'

const {logo, navItems, socials} = NavbarConfig

function Navigation() {

    return (
        <>
            <div className='w-full bg-white justify-between fixed left-0 top-0 p-4 text-black items-center hidden sm:flex sm:flex-col sm:h-screen sm:w-60'>
                <div className='h-20 aspect-square my-8 sm:h-60'>
                    <img src={Logo} alt='logo' />
                </div>
                <ul className='list-none flex text-left mb-auto mt-4 sm:flex-col gap-4'>
                    {navItems.map((navItem, index) => {
                        return (
                            <li key={index} className='list-none -ml-20 hover:text-red-500'>
                                <a href={navItem.path} className='no-underline text-black hover:text-red-500'>{navItem.name}</a>
                            </li>
                        )
                    })}
                </ul>
                <span className='flex flex-row gap-4'>
                    <div className='w-8'>
                        <Link to='#'>
                            <img src={TwitterIcon} alt='twitter-icon' className='w-full'/>
                        </Link>
                    </div>
                    <div className='w-8'>
                        <Link to='https://instagram.com/studioqinghua'>
                            <img src={InstagramIcon} alt='instagram-icon' className='w-full'/>  
                        </Link>
                    </div> 
                </span>
            </div>

            <MobileNavigation />
        </>
    )
}

function MobileNavigation() {
    return (
        <div className='w-full sm:hidden border-b-2 border-gray-200'>
            <Navbar bg="white" variant="light" fixed='top' expand={false}>
                <Container>
                    <Navbar.Brand href="/">
                        <img 
                            src={Logo} 
                            width='55'
                            height='55'
                            alt='logo' 
                            className="d-inline-block align-top" />
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                    <Navbar.Collapse id="responsive-navbar-nav">
                        <Nav className="me-auto">
                            {navItems.map((navItem, index) => {
                                return (
                                    <Nav.Link key={index} href={navItem.path}>
                                        {navItem.name}
                                    </Nav.Link>
                                )
                            })}
                            <span className='flex flex-row gap-4 mt-auto mb-0'>
                                <Nav.Link href="#features">
                                    <img 
                                        width='20'
                                        height='20'
                                        src={TwitterIcon} 
                                        alt='twitter-icon' />
                                </Nav.Link>
                                <Nav.Link href="https://instagram.com/studioqinghua">
                                    <img 
                                        width='20'
                                        height='20'
                                        src={InstagramIcon} 
                                        alt='instagram-icon'/>  
                                </Nav.Link>
                            </span>
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        </div>
    )
}

export default Navigation