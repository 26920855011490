import React from 'react'
import ShopPiece from './ShopPiece'

function ShopGrid({items}) {
    return (
        <div className='w-full h-full grid grid-cols-2 gap-2'>
            {items.map((item, index) => {
                return (
                    <ShopPiece key={index} item={item} />
                )
            })}
        </div>
    )
}

export default ShopGrid