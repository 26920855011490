import React from 'react'
import config from './AboutMeConfig'

function AboutMe() {
    return (
        <div className='py-4 px-4 sm:px-28'>
            <h1>About me</h1>
            {config.text.map((paragraph, index) => {
                return (
                    <span key={index} className='flex flex-col items-center gap-y-6 sm:gap-y-10'>
                        <img src={config.images[index]} alt={`about-me-${index}`} className='w-full rounded-lg shadow-lg'/>
                        <p className='text-lg sm:w-4/5 sm:text-2xl'>{paragraph}</p>
                    </span>
                )
            })}
        </div>
    )
}

export default AboutMe