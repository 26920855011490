import React from 'react'
import { Link, Outlet } from 'react-router-dom'
import Navigation from './nav/Navigation'
import UnderConstruction from '../../assets/under-construction.png'
import UnderConstructionMobile from '../../assets/under-construction-mobile.png'

function Layout() {
    return (
        // <div className='w-full max-w-screen min-h-[100vh] pt-20 bg-under-construction bg-cover font-sans text-black flex flex-col sm:flex-row sm:pl-60 sm:pt-8'>
        //     <Navigation />
        //     <Outlet />
        // </div>
        <div className='w-screen sm:h-screen'>
            <img src={UnderConstruction} alt='under-construction' className='absolute top-0 left-0 w-full h-full hidden sm:block'/>
            <img src={UnderConstructionMobile} alt='under-construction-mobile' className='absolute top-0 left-0 w-full block sm:hidden'/>
        </div>
    )
}

export default Layout