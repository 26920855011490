// import Artemis from '../../../assets/art/artemis.jpg'
// import Blauwsel from '../../../assets/art/blauwsel.jpg'
// import Blossom from '../../../assets/art/blossom.jpg'
// import BlossomCollection from '../../../assets/collections/blossom.jpg'
// import CosmicEgg from '../../../assets/art/cosmic-egg.jpg'
// import JRHT23 from '../../../assets/art/jrht-23.jpg'
// import Muse from '../../../assets/art/muse.jpg'
// import MuseCollection from '../../../assets/collections/muse.jpg'
// import Muse2 from '../../../assets/art/muse-2.jpg'
// import OrderInChaos from '../../../assets/art/order-in-chaos.jpg'
// import SightBeyondSight from '../../../assets/art/sight-beyond-sight.jpg'

export const ArtConfig = {
    defaultCollectionConfig: {
        name: '',
        pieces: [{
            name: '',
            file: null,
            artist: '',
            canvasDimensions: '',
            description: '',
            price: ''
        },]
    },
    homePage: {
        special: {
            name: 'Cosmic Crown Qing',
            id: 'blauwsel',
            file: 'https://d2hfcuq72ogdij.cloudfront.net/portfolio/chaos/blauwsel.jpg',
            artist: 'DevonQing',
            canvasDimensions: '60 cm x 80 cm',
            description: 'This painting was inspired by the Reckkit\'s Crown blue pigment which is a big part of Surinamese culture. This pigment is conventionally used for keeping white fabric clean but when it comes to spirituality, it is used as protection againt the ominous evil eye.',
            price: 'Price by request',
            collectionId: 'chaos'
        },
        pieces: [
            {
                name: 'Muse',
                file: 'https://d2hfcuq72ogdij.cloudfront.net/portfolio/chaos/muse.jpg',
                collectionImage: 'https://d2hfcuq72ogdij.cloudfront.net/portfolio/chaos/muse.jpg',
                id: 'muse',
                artist: 'DevonQing',
                canvasDimensions: '60 cm x 80 cm',
                description: 'Acrylic on canvas (2023)',
                price: 'Price by request',
                collectionName: 'Chaos',
                collectionId: 'chaos',
            },
            {
                name: 'The Faceless Blue',
                file: 'https://d2hfcuq72ogdij.cloudfront.net/portfolio/chaos/order-in-chaos.jpg',
                id: 'the-faceless-blue',
                artist: 'DevonQing',
                canvasDimensions: '60 cm x 80 cm',
                description: 'Acrylic on canvas (2023)',
                price: 'Price by request',
                collectionName: 'Silhouette',
                collectionId: 'silhouette'
            },
            {
                name: 'Blossom',
                file: 'https://d2hfcuq72ogdij.cloudfront.net/home/blossom.jpg',
                collectionImage: 'https://d2hfcuq72ogdij.cloudfront.net/home/blossom.jpg',
                id: 'blossom',
                artist: 'DevonQing',
                canvasDimensions: '60 cm x 80 cm',
                description: 'Acrylic on canvas (2023)',
                price: 'Price by request',
                collectionName: 'Spring',
                collectionId: 'spring',
            },
        ]
    },
    collections: {
        chaos: {
            name: 'Chaos',
            url: '/art/collections/chaos',
            pieces: [
                {
                    name: 'Muse',
                    file: 'https://d2hfcuq72ogdij.cloudfront.net/portfolio/chaos/muse.jpg',
                    collectionImage: 'https://d2hfcuq72ogdij.cloudfront.net/portfolio/chaos/muse.jpg',
                    id: 'muse',
                    artist: 'DevonQing',
                    canvasDimensions: '60 cm x 80 cm',
                    description: 'Acrylic on canvas (2023)',
                    price: 'Price by request',
                    collectionId: 'chaos'
                },
                {
                    name: 'Cosmic Crown Qing',
                    id: 'blauwsel',
                    file: 'https://d2hfcuq72ogdij.cloudfront.net/portfolio/chaos/blauwsel.jpg',
                    artist: 'DevonQing',
                    canvasDimensions: '60 cm x 80 cm',
                    description: 'This painting was inspired by the Reckkit\'s Crown blue pigment which is a big part of Surinamese culture. This pigment is conventionally used for keeping white fabric clean but when it comes to spirituality, it is used as protection againt the ominous evil eye.',
                    price: 'Price by request',
                    collectionId: 'chaos'
                },
                // {
                //     name: 'The Cosmic Egg',
                //     file: CosmicEgg,
                //     id: 'cosmic-egg',
                //     artist: 'DevonQing',
                //     canvasDimensions: '60 cm x 80 cm',
                //     description: 'Acrylic on canvas (2023)',
                //     price: 'Price by request',
                //     collectionId: 'chaos'
                // },
                {
                    name: 'JRHT \'23',
                    file: 'https://d2hfcuq72ogdij.cloudfront.net/portfolio/chaos/jrht-23.jpg',
                    id: 'jrht-23',
                    artist: 'DevonQing',
                    canvasDimensions: '60 cm x 80 cm',
                    description: 'Acrylic on canvas (2023)',
                    price: 'Price by request',
                    collectionId: 'chaos'
                },
                {
                    name: 'Muse II',
                    file: 'https://d2hfcuq72ogdij.cloudfront.net/portfolio/chaos/muse-2.jpg',
                    id: 'muse-2',
                    artist: 'DevonQing',
                    canvasDimensions: '60 cm x 80 cm',
                    description: 'Acrylic on canvas (2023)',
                    price: 'Price by request',
                    collectionId: 'chaos'
                },
                {
                    name: 'The Faceless Blue',
                    file: 'https://d2hfcuq72ogdij.cloudfront.net/portfolio/chaos/order-in-chaos.jpg',
                    id: 'the-faceless-blue',
                    artist: 'DevonQing',
                    canvasDimensions: '60 cm x 80 cm',
                    description: 'Acrylic on canvas (2023)',
                    price: 'Price by request',
                    collectionId: 'chaos'
                },
                // {
                //     name: 'Blossom',
                //     file: Blossom,
                //     collectionImage: BlossomCollection,
                //     id: 'blossom',
                //     artist: 'DevonQing',
                //     canvasDimensions: '60 cm x 80 cm',
                //     description: 'Acrylic on canvas (2023)',
                //     price: 'Price by request',
                //     collectionId: 'chaos'
                // },
                // {
                //     name: 'Artemis',
                //     file: Artemis,
                //     id: 'artemis',
                //     artist: 'DevonQing',
                //     canvasDimensions: '60 cm x 80 cm',
                //     description: 'Acrylic on canvas (2023)',
                //     price: 'Price by request',
                //     collectionId: 'chaos'
                // },
                {
                    name: 'Sight Beyond Sight',
                    file: 'https://d2hfcuq72ogdij.cloudfront.net/portfolio/chaos/sight-beyond-sight.jpg',
                    id: 'sight-beyond-sight',
                    artist: 'DevonQing',
                    canvasDimensions: '60 cm x 80 cm',
                    description: 'Acrylic on canvas (2023)',
                    price: 'Price by request',
                    collectionId: 'chaos'
                },
            ]
        }
    }
}