import React from 'react'
import { Link } from 'react-router-dom'
import { ArtConfig } from '../config/ArtConfig'

function Collections() {
    const collections = ArtConfig.collections
    const collectionNames = Object.keys(collections)
    return (
        <div className='relative w-full h-screen'>
            <div className='grid md:grid-cols-3 w-full h-full'>
                {collectionNames.map((collectionName, index) => {
                    const collection = collections[collectionName]
                    const firstPiece = collection.pieces[0]
                    const secondPiece = collection.pieces[1]
                    return (
                        <div key={index} className='relative w-full h-screen overflow-hidden'>
                            <Link to={collection.url}>
                                <img src={firstPiece.collectionImage} alt='first-image-of-collection' className='absolute h-screen fixed top-0 left-0 scale-120' />
                                <p className='absolute font-bold text-3xl text-white z-10 bottom-16 text-center w-full uppercase'>{collection.name}</p>
                            </Link>
                        </div>
                    )
                })}
            </div>
        </div>
    )
}

export default Collections