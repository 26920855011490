import React, { useEffect, useState } from 'react'
import PieceGrid from '../pieces/PieceGrid'
import { useParams } from 'react-router-dom'
import { ArtConfig } from '../config/ArtConfig'
import BackButton from '../../layout/nav/BackButton'

function Collection() {
    const {collectionName} = useParams()
    const [pageConfig, setPageConfig] = useState(ArtConfig.defaultCollectionConfig)

    useEffect(() => {
        if(ArtConfig.collections[collectionName]) {
            setPageConfig(prev => {
                const collectionConfig = ArtConfig.collections[collectionName]
                return {
                    name: collectionConfig.name,
                    collectionId: collectionName,
                    pieces: collectionConfig.pieces
                }
            })
        }
    }, [])

    return (
        <div className='relative w-full h-full px-4'>
            <BackButton styles='absolute top-6 right-6'/>
            <h1>{pageConfig.name}</h1>
            <PieceGrid pieceConfig={pageConfig}/>
        </div>
    )
}

export default Collection