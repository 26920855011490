import React from 'react'
import Carousel from 'react-bootstrap/Carousel'
import { ArtConfig } from '../components/art/config/ArtConfig'
import homePainting from '../assets/all/home/home-painting.jpeg'
import { Link, useNavigate } from 'react-router-dom'
import Piece2 from '../components/art/pieces/Piece2'
import shopConfig from '../components/shop/ShopConfig'

function Home() {
    const featuredPiece = ArtConfig.homePage.special
    const featuredPieces = ArtConfig.homePage.pieces
    const shopPieces = shopConfig.homeCarousel
    const navigate = useNavigate()

    return (
        <div className='w-full h-full grid grid-cols-1 pt-4 sm:py-6 sm:px-12'>
            <section className='block relative shadow-lg shadow-black h-[50vh] block bg-home-carousel bg-no-repeat bg-center bg-cover sm:h-[85vh] sm:w-full sm:mx-auto sm:bg-bottom-home-carousel'>
                <Carousel className='mx-auto w-4/5 mt-20 p-2 bg-white sm:mt-48 sm:w-[30vw]'>
                    {featuredPieces.map((piece, index) => {
                        return (
                            <Carousel.Item key={index}>
                                <Link to={`/art/collections/${piece.collectionId}/${piece.id}`}>
                                    <div className='h-full'>
                                        <img
                                        className="d-block h-full"
                                        src={piece.file}
                                        alt={piece.name}
                                        />
                                    </div>
                                </Link>
                            </Carousel.Item>
                        )
                    })}
                </Carousel>
            </section>
            <section className='block h-fit relative flex items-center h-[35vh] sm:bg-contain sm:bg-no-repeat sm:bg-center sm:h-screen sm:w-full'>
                <div className='absolute sm:top-[25%] h-3/4 z-0'>
                    <img src='https://d2hfcuq72ogdij.cloudfront.net/home/home-painting.jpeg' alt='home-painting' className='sm:h-full sm:hidden'/>
                </div>
                <div className='z-10 w-full flex py-16'>
                    <button onClick={() => navigate('/#')} className='text-white font-semibold bg-gray-200/70 rounded-full px-4 py-2 ml-auto mr-4 shadow-xl shadow-black hover:bg-gray-200/90'>Article</button>
                </div>
            </section>
            <section className='block flex items-center h-[20vh] sm:h-screen mb-8'>
                <Carousel className='mx-auto w-full p-2 bg-white h-full sm:h-3/4'>
                    {shopPieces.map((slides, index) => {
                        return (
                            <Carousel.Item key={index}>
                                <div className='grid grid-cols-3 gap-x-4 shadow-lg shadow-black'>
                                    {slides.map((slide, index) => {
                                        return (
                                            <div className='h-full overflow-hidden' key={1000 + index}>
                                                <img src={slide.source} alt={slide.title.toLowerCase()} className='h-full'/>
                                            </div>
                                        )
                                    })}
                                    {/* <div className='h-full overflow-hidden'>
                                        <img src={piece.source} alt={piece.title.toLowerCase()} className='h-full'/>
                                    </div>
                                    <div className='h-full overflow-hidden'>
                                        <img src={piece.carouselSide[1]} alt={piece.title.toLowerCase()} className='h-full'/>
                                    </div> */}
                                </div>
                            </Carousel.Item>
                        )
                    })}
                </Carousel>
            </section>
            {/* <section className='block max-h-screen px-4 relative flex items-center'>
                <Piece2 pieceConfig={featuredPiece}/>
            </section> */}
        </div>
    )
}

export default Home